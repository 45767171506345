import { render, staticRenderFns } from "./dashboard-employee-transfers.vue?vue&type=template&id=24a129d2&"
import script from "./dashboard-employee-transfers.vue?vue&type=script&lang=js&"
export * from "./dashboard-employee-transfers.vue?vue&type=script&lang=js&"
import style0 from "./dashboard-employee-transfers.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/frank/Documents/RacknerProjects/Bobtail/bobtail_reset_6-3/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24a129d2')) {
      api.createRecord('24a129d2', component.options)
    } else {
      api.reload('24a129d2', component.options)
    }
    module.hot.accept("./dashboard-employee-transfers.vue?vue&type=template&id=24a129d2&", function () {
      api.rerender('24a129d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/dashboard-employee-transfers.vue"
export default component.exports