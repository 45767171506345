var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["Table", { "Table--mobile": _vm.mobile.isMobile }],
      attrs: { "data-cy": "base-wires-table" }
    },
    [
      _vm._t("default"),
      _vm._m(0),
      _vm._l(_vm.wires, function(wire, index) {
        return _c(
          "div",
          { key: index, staticClass: "Table__item--BaseWiresTable" },
          [
            _c(
              "div",
              {
                staticClass:
                  "Table__item-wrapper Table__item-wrapper--BaseWiresTable row row--align-center"
              },
              [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "label",
                    {
                      staticClass: "fs-15 fc-light",
                      attrs: {
                        "data-cy": "base-wires-table-" + index + "-amount-label"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD"
                            }).format(wire.wire_total / 100)
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column" }, [
                  _c(
                    "label",
                    {
                      staticClass: "fs-15 fc-light clickable",
                      attrs: {
                        "data-cy":
                          "base-wires-table-" + index + "-shortened-name",
                        tabindex: "0"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.goToClientProfile(wire.id)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(wire.shortened_name) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "column column--align-end" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass:
                          "BaseWiresTable__send-wire-btn bg-blue fc-white fs-14",
                        attrs: {
                          "data-cy":
                            "base-wires-table-" + index + "-expedite-button"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showModal(wire)
                          }
                        }
                      },
                      [_vm._v("\n          Expedite Wire\n        ")]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        )
      }),
      _c(
        "transition",
        { attrs: { name: "Transition__fade" } },
        [
          _vm.modalVisible
            ? _c("base-modal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n        Do you want to expedite a wire for\n        " +
                              _vm._s(
                                Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                }).format(_vm.wireToExpedite.wire_total / 100)
                              ) +
                              "\n        to\n        " +
                              _vm._s(_vm.wireToExpedite.shortened_name) +
                              "?\n      "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white mr-8",
                              attrs: {
                                "data-cy": "base-wires-table-send-button",
                                disabled: _vm.sendButtonDisabled
                              },
                              on: { click: _vm.expediteClientWire }
                            },
                            [_vm._v("\n          Send\n        ")]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-light fc-white",
                              on: { click: _vm.hideModal }
                            },
                            [_vm._v("\n          Cancel\n        ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3033233587
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "Table__head-wrapper row row--justify-between" },
      [
        _c(
          "label",
          {
            staticClass: "Table__header-lbl Table__header-lbl--BaseWiresTable"
          },
          [_vm._v("Amount")]
        ),
        _c(
          "label",
          {
            staticClass: "Table__header-lbl Table__header-lbl--BaseWiresTable"
          },
          [_vm._v("Client")]
        ),
        _c("label", {
          staticClass: "Table__header-lbl Table__header-lbl--BaseWiresTable"
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }