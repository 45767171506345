var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Table", attrs: { "data-cy": "base-ach-table" } },
    [
      _vm._t("default"),
      _vm._m(0),
      _vm._l(_vm.dataTransfers, function(transfer, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "Table__item--BaseACHTable clickable",
            on: {
              click: function($event) {
                return _vm.expandTransfer(index)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "Table__item-wrapper Table__item-wrapper--BaseACHTable row row--align-center"
              },
              [
                _c("div", { staticClass: "column" }, [
                  _c(
                    "label",
                    {
                      staticClass: "fs-15 fc-light",
                      attrs: { "data-cy": "base-ach-table-amount-label" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD"
                            }).format(transfer.ach_total / 100)
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("label", { staticClass: "fs-15 fc-light" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(transfer.transfer_time) +
                        "\n        "
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "column column--align-end" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white",
                        attrs: { "data-cy": "base-ach-table-initiate-btn" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            _vm.modalVisible = true
                          }
                        }
                      },
                      [_vm._v("\n          Initiate ACH Transfer\n        ")]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("transition", { attrs: { name: "Transition__fade" } }, [
              transfer.expanded
                ? _c(
                    "div",
                    {
                      staticClass:
                        "Table__item-expanded-wrapper--BaseACHTable bg column"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "Table__item-expanded-wrapper width-100"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "Table__head-wrapper--expanded row row--justify-between"
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "Table__header-lbl Table__header-lbl--BaseACHTable"
                                },
                                [_vm._v("Amount")]
                              ),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "Table__header-lbl Table__header-lbl--BaseACHTable"
                                },
                                [_vm._v("Client")]
                              )
                            ]
                          ),
                          _vm._l(transfer.clients, function(
                            client,
                            clientIndex
                          ) {
                            return _c(
                              "div",
                              {
                                key: clientIndex,
                                staticClass:
                                  "\n              Table__item-wrapper Table__item-wrapper--BaseACHTable\n              row row--align-center\n            "
                              },
                              [
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "label",
                                    { staticClass: "fc-light fs-14" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: "USD"
                                            }).format(client.ach_total / 100)
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "column" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "fc-mid fs-14",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.goToClientProfile(
                                            client.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(client.shortened_name) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e()
            ])
          ],
          1
        )
      }),
      _c(
        "transition",
        { attrs: { name: "Transition__fade" } },
        [
          _vm.modalVisible
            ? _c("base-modal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n        Do you want to initiate this ACH transfer for\n        " +
                              _vm._s(
                                Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD"
                                }).format(_vm.dataTransfers[0].ach_total / 100)
                              ) +
                              "\n      "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white mr-8",
                              attrs: {
                                "data-cy": "base-ach-table-send-button",
                                disabled: _vm.sendButtonDisabled
                              },
                              on: { click: _vm.runACHTransfer }
                            },
                            [_vm._v("\n          Send\n        ")]
                          ),
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-light fc-white",
                              on: { click: _vm.hideModal }
                            },
                            [_vm._v("\n          Cancel\n        ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2230793709
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "Table__head-wrapper row row--justify-between" },
      [
        _c(
          "label",
          { staticClass: "Table__header-lbl Table__header-lbl--BaseACHTable" },
          [_vm._v("Amount")]
        ),
        _c(
          "label",
          { staticClass: "Table__header-lbl Table__header-lbl--BaseACHTable" },
          [_vm._v("Arrival")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }