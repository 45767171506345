<template>
  <div class="Table">
    <div class="Table__head-wrapper row row--justify-between">
      <label class="Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable">Date</label>
      <label class="Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable">ETA</label>
      <label class="Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable">Type</label>
      <label class="Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable">Amount</label>
      <label class="Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable">Status</label>
    </div>
    <div
      v-for="(transfer, index) in transfers"
      :key="index"
      :id="`Testing__EmployeeTransferRow-${index}`"
      @click="expandTransfer(transfer)"
      class="clickable"
    >
      <div class="Table__item-wrapper Table__item-wrapper--BaseEmployeeTransferTable row">
        <!-- ARRIVAL -->
        <time class="column fs-14 uppercase">
          <time
            class="Table__time-lbl Table__time-lbl--BaseClientTransferTable fs-15"
            :datetime="transfer.updated_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ transfer.updated_at | datetime('h:mma') }}
          </time>
          <time
            class="Table__date-lbl Table__date-lbl--BaseClientTransferTable fc-light fs-13"
            :datetime="transfer.updated_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ transfer.updated_at | datetime('M/D/YY') }}
          </time>
        </time>

        <!-- ETA -->
        <time class="column fs-14 uppercase">
          <time
            class="Table__time-lbl Table__time-lbl--BaseClientTransferTable fs-15"
            :datetime="transfer.expected_payment_date | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ transfer.expected_payment_date | datetime('h:mma') }}
          </time>
          <time
            class="Table__date-lbl Table__date-lbl--BaseClientTransferTable fc-light fs-13"
            :datetime="transfer.expected_payment_date | datetime('YYYY-MM-DD hh:mm:ssZ')"
          >
            {{ transfer.expected_payment_date | datetime('M/D/YY') }}
          </time>
        </time>

        <!-- TYPE -->
        <label
          class="column fs-14 uppercase"
          :id="`Testing__EmployeeTransferType-${index}`"
        >
          {{ transfer.transfer_type }}
        </label>

        <!-- AMOUNT -->
        <label class="column fs-14">
          {{
            Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format((transfer.amount / 100))
          }}
        </label>

        <!-- STATUS -->
        <div class="column column--align-end">
          <!-- re-send -->
          <button
            v-if="failedTransferStatuses.indexOf(transfer.status) !== -1"
            @click.stop="rerunNacha(transfer)"
            class="
              Table__status-flag
              Table__status-flag--resend fs-12 uppercase mr-10
            "
          >
            Resend
          </button>
          <label
            v-if="failedTransferStatuses.indexOf(transfer.status) === -1"
            :class="['Table__status-flag fc-light fs-12 uppercase', {
              'Table__status-flag--failed':
                emptyTransferStatuses.indexOf(transfer.status) !== -1,
              'Table__status-flag--success':
                successfulTransferStatuses.indexOf(transfer.status) !== -1
            }
            ]"
            :id="`Testing__EmployeeTransferStatus-${index}`"
          >
            {{ transfer.status }}
          </label>
          <label
            v-if="failedTransferStatuses.indexOf(transfer.status) !== -1"
            class="
              Table__status-flag
              Table__status-flag--failed fc-light fs-12 uppercase
            "
          >
            Failed
          </label>
        </div>
      </div>

      <transition name="Transition__fade">
        <div
          v-if="transfer.expanded"
          class="Table__item-expanded-wrapper--BaseEmployeeTransferTable column"
        >
          <div class="Table__head-wrapper Table__head-wrapper--expanded row">
            <!-- 5 colums to match alignment with main table row -->
            <!-- TRANSFER ID and TRANSFER AUTHOR -->
            <label class="Table__header-lbl--BaseEmployeeTransferTable fc-light fs-11">
              Transfer ID: {{ transfer.display_id }} by
              {{
                transfer.client_payments[0].updated_by_user.employee
                  ? transfer.client_payments[0].updated_by_user.employee.first_name
                  : 'System'
              }}
            </label>
            <label class="Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable">Client</label>
            <label class="Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable" />
            <label class="Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable">Amount</label>
            <label class="Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable" />
          </div>
          <div
            v-for="(clientPayment, subIndex) in transfer.client_payments"
            :key="subIndex"
            class="Table__item-wrapper Table__item-wrapper--BaseEmployeeTransferTable row"
          >
            <!-- 5 columns to match alignment with main table row -->
            <div class="column" />
            <!-- Client shortened name shouldn't be altered from underwriter/client's input -->
            <!-- https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1639 -->
            <!-- CLIENT -->
            <div class="column">
              <label
                @click.stop="goToClientProfile(clientPayment.client.id)"
                class="fc-mid fs-14"
                :id="`Testing__TransferClientName-${index}`"
              >
                {{ clientPayment.client.shortened_name }}
              </label>
            </div>
            <div class="column" />
            <!-- AMOUNT -->
            <div class="column">
              <label
                class="fc-light fs-14 uppercase"
                :id="`Testing__TransferClientPaymentAmount-${index}`"
              >
                {{
                  Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                    .format((clientPayment.amount / 100))
                }}
              </label>
            </div>
            <div class="column" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// Helpers
import { Cron } from '../utils/api'

export default {
  name: 'BaseEmployeeTransferTable',

  props: {
    transfers: {
      type: Array,
      required: true,
    },
  },

  data () {
    return {
      emptyTransferStatuses: [
        'in-calculation',
        'not-sent-0-dollars'
      ],
      failedTransferStatuses: [
        'declined1',
        'declined2',
        'declined3',
        'declined4',
        'declined5'
      ],
      successfulTransferStatuses: [
        'sent',
        'accepted'
      ]
    }
  },

  methods: {
    expandTransfer (transfer) {
      if (transfer.expanded) {
        this.$set(transfer, 'expanded', false)
        return
      }

      if (this.emptyTransferStatuses.indexOf(transfer.status) !== -1) {
        this.alertWarning('This is an empty transfer')
        return
      }

      this.$set(transfer, 'expanded', true)
    },

    goToClientProfile (clientId) {
      this.$router.push({
        name: 'client',
        params: {
          id: clientId,
        }
      })
    },

    async rerunNacha (transfer) {
      this.progressStart()

      try {
        await Cron.reRunNachaJob(transfer)
        this.$emit('reload-nacha')
        this.requestSuccess({ message: 'Reran the ach successfully' })
      } catch (error) {
        this.captureSentryEvent(
          'Employee Transfer "Run Nacha Job" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue running the ach transfer' })
      }
    }
  },

}
</script>

<style lang="sass">
@import '../styles/tables'
</style>