<template>
  <div
    class="Table"
    data-cy="base-ach-table"
  >
    <!-- If you want a heading and subheading in the top of the table -->
    <!-- Content passed here should have class: Table__slot-content -->
    <slot />

    <div class="Table__head-wrapper row row--justify-between">
      <label class="Table__header-lbl Table__header-lbl--BaseACHTable">Amount</label>
      <label class="Table__header-lbl Table__header-lbl--BaseACHTable">Arrival</label>
    </div>

    <div
      v-for="(transfer, index) in dataTransfers"
      :key="index"
      class="Table__item--BaseACHTable clickable"
      @click="expandTransfer(index)"
    >
      <div class="Table__item-wrapper Table__item-wrapper--BaseACHTable row row--align-center">
        <!-- AMOUNT -->
        <div class="column">
          <label
            class="fs-15 fc-light"
            :data-cy="`base-ach-table-amount-label`"
          >
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format((transfer.ach_total / 100))
            }}
          </label>
        </div>

        <!-- ARRIVAL -->
        <div class="column">
          <label class="fs-15 fc-light">
            {{ transfer.transfer_time }}
          </label>
        </div>

        <div class="column column--align-end">
          <!--
            NOTE: We're putting this in the actual row to keep the design similar to upcoming
            wires; however, there should only ever be ONE upcoming ACH transfer at any given time.
            If there happen to be more than ONE, this button will initiate ALL the transfers
          -->
          <base-button
            @click.stop="modalVisible = true"
            class="bg-blue fc-white"
            data-cy="base-ach-table-initiate-btn"
          >
            Initiate ACH Transfer
          </base-button>
        </div>
      </div>


      <transition name="Transition__fade">
        <div
          v-if="transfer.expanded"
          class="Table__item-expanded-wrapper--BaseACHTable bg column"
        >
          <div class="Table__item-expanded-wrapper width-100">
            <div class="Table__head-wrapper--expanded row row--justify-between">
              <label class="Table__header-lbl Table__header-lbl--BaseACHTable">Amount</label>
              <label class="Table__header-lbl Table__header-lbl--BaseACHTable">Client</label>
            </div>

            <div
              v-for="(client, clientIndex) in transfer.clients"
              :key="clientIndex"
              class="
                Table__item-wrapper Table__item-wrapper--BaseACHTable
                row row--align-center
              "
            >
              <!-- AMOUNT -->
              <div class="column">
                <label class="fc-light fs-14">
                  {{
                    Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                      .format((client.ach_total / 100))
                  }}
                </label>
              </div>

              <!-- CLIENT SHORT NAME -->
              <div class="column">
                <label
                  @click.stop="goToClientProfile(client.id)"
                  class="fc-mid fs-14"
                >
                  {{ client.shortened_name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <transition name="Transition__fade">
      <base-modal v-if="modalVisible">
        <template v-slot:label>
          Do you want to initiate this ACH transfer for
          {{
            Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format((dataTransfers[0].ach_total / 100))
          }}
        </template>

        <template v-slot:actions>
          <base-button
            @click="runACHTransfer"
            class="bg-blue fc-white mr-8"
            data-cy="base-ach-table-send-button"
            :disabled="sendButtonDisabled"
          >
            Send
          </base-button>
          <base-button
            @click="hideModal"
            class="bg-light fc-white"
          >
            Cancel
          </base-button>
        </template>
      </base-modal>
    </transition>
  </div>
</template>

<script>
// Helpers
import { Cron } from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseModal from './base-modal.vue'

export default {
  name: 'BaseAchTable',

  components: {
    BaseButton,
    BaseModal,
  },

  props: {
    transfers: {
      type: Array,
      required: false,
      default: null
    },
  },

  data () {
    return {
      dataTransfers: this.transfers,
      modalVisible: false,
      sendButtonDisabled: false,
    }
  },

  methods: {
    expandTransfer (index) {
      this.$set(this.dataTransfers[index], 'expanded', !this.dataTransfers[index].expanded)
    },

    hideModal () {
      this.modalVisible = false
      this.sendButtonDisabled = false
    },

    goToClientProfile (clientId) {
      this.$router.push({
        name: 'client',
        params: {
          id: clientId,
        }
      })
    },

    async runACHTransfer () {
      this.sendButtonDisabled = true
      this.progressStart()

      try {
        await Cron.runNachaJob()
        this.modalVisible = false
        // The button should be displayed at the very last step
        this.sendButtonDisabled = false
        this.$emit('ach-sent')
        this.requestSuccess({ message: 'The ACH Transfer has been initiated' })
      }
      // I don't want to enable the submit button on an error.
      // I really want to make sure it's clear something went wrong
      // and a user should just try again. If the user clicks "cancel,"
      // the button will re-enabled
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Transfers "Run ACH Transfer"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: `
            There was an issue running the ach transfer.
            Please reach out to your administrators to get more information on the error.
          `
        })
      }
    },
  },
}
</script>

<style lang="sass">
@import '../styles/tables.sass'
</style>