var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Table" },
    [
      _vm._m(0),
      _vm._l(_vm.transfers, function(transfer, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "clickable",
            attrs: { id: "Testing__EmployeeTransferRow-" + index },
            on: {
              click: function($event) {
                return _vm.expandTransfer(transfer)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "Table__item-wrapper Table__item-wrapper--BaseEmployeeTransferTable row"
              },
              [
                _c("time", { staticClass: "column fs-14 uppercase" }, [
                  _c(
                    "time",
                    {
                      staticClass:
                        "Table__time-lbl Table__time-lbl--BaseClientTransferTable fs-15",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          transfer.updated_at,
                          "YYYY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("datetime")(transfer.updated_at, "h:mma")
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "time",
                    {
                      staticClass:
                        "Table__date-lbl Table__date-lbl--BaseClientTransferTable fc-light fs-13",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          transfer.updated_at,
                          "YYYY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("datetime")(transfer.updated_at, "M/D/YY")
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c("time", { staticClass: "column fs-14 uppercase" }, [
                  _c(
                    "time",
                    {
                      staticClass:
                        "Table__time-lbl Table__time-lbl--BaseClientTransferTable fs-15",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          transfer.expected_payment_date,
                          "YYYY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("datetime")(
                              transfer.expected_payment_date,
                              "h:mma"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "time",
                    {
                      staticClass:
                        "Table__date-lbl Table__date-lbl--BaseClientTransferTable fc-light fs-13",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          transfer.expected_payment_date,
                          "YYYY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("datetime")(
                              transfer.expected_payment_date,
                              "M/D/YY"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "column fs-14 uppercase",
                    attrs: { id: "Testing__EmployeeTransferType-" + index }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(transfer.transfer_type) + "\n      "
                    )
                  ]
                ),
                _c("label", { staticClass: "column fs-14" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD"
                        }).format(transfer.amount / 100)
                      ) +
                      "\n      "
                  )
                ]),
                _c("div", { staticClass: "column column--align-end" }, [
                  _vm.failedTransferStatuses.indexOf(transfer.status) !== -1
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "\n            Table__status-flag\n            Table__status-flag--resend fs-12 uppercase mr-10\n          ",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.rerunNacha(transfer)
                            }
                          }
                        },
                        [_vm._v("\n          Resend\n        ")]
                      )
                    : _vm._e(),
                  _vm.failedTransferStatuses.indexOf(transfer.status) === -1
                    ? _c(
                        "label",
                        {
                          class: [
                            "Table__status-flag fc-light fs-12 uppercase",
                            {
                              "Table__status-flag--failed":
                                _vm.emptyTransferStatuses.indexOf(
                                  transfer.status
                                ) !== -1,
                              "Table__status-flag--success":
                                _vm.successfulTransferStatuses.indexOf(
                                  transfer.status
                                ) !== -1
                            }
                          ],
                          attrs: {
                            id: "Testing__EmployeeTransferStatus-" + index
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(transfer.status) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.failedTransferStatuses.indexOf(transfer.status) !== -1
                    ? _c(
                        "label",
                        {
                          staticClass:
                            "\n            Table__status-flag\n            Table__status-flag--failed fc-light fs-12 uppercase\n          "
                        },
                        [_vm._v("\n          Failed\n        ")]
                      )
                    : _vm._e()
                ])
              ]
            ),
            _c("transition", { attrs: { name: "Transition__fade" } }, [
              transfer.expanded
                ? _c(
                    "div",
                    {
                      staticClass:
                        "Table__item-expanded-wrapper--BaseEmployeeTransferTable column"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "Table__head-wrapper Table__head-wrapper--expanded row"
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "Table__header-lbl--BaseEmployeeTransferTable fc-light fs-11"
                            },
                            [
                              _vm._v(
                                "\n            Transfer ID: " +
                                  _vm._s(transfer.display_id) +
                                  " by\n            " +
                                  _vm._s(
                                    transfer.client_payments[0].updated_by_user
                                      .employee
                                      ? transfer.client_payments[0]
                                          .updated_by_user.employee.first_name
                                      : "System"
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c(
                            "label",
                            {
                              staticClass:
                                "Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable"
                            },
                            [_vm._v("Client")]
                          ),
                          _c("label", {
                            staticClass:
                              "Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable"
                          }),
                          _c(
                            "label",
                            {
                              staticClass:
                                "Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable"
                            },
                            [_vm._v("Amount")]
                          ),
                          _c("label", {
                            staticClass:
                              "Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable"
                          })
                        ]
                      ),
                      _vm._l(transfer.client_payments, function(
                        clientPayment,
                        subIndex
                      ) {
                        return _c(
                          "div",
                          {
                            key: subIndex,
                            staticClass:
                              "Table__item-wrapper Table__item-wrapper--BaseEmployeeTransferTable row"
                          },
                          [
                            _c("div", { staticClass: "column" }),
                            _c("div", { staticClass: "column" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "fc-mid fs-14",
                                  attrs: {
                                    id: "Testing__TransferClientName-" + index
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.goToClientProfile(
                                        clientPayment.client.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        clientPayment.client.shortened_name
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "column" }),
                            _c("div", { staticClass: "column" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "fc-light fs-14 uppercase",
                                  attrs: {
                                    id:
                                      "Testing__TransferClientPaymentAmount-" +
                                      index
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD"
                                        }).format(clientPayment.amount / 100)
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]),
                            _c("div", { staticClass: "column" })
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "Table__head-wrapper row row--justify-between" },
      [
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable"
          },
          [_vm._v("Date")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable"
          },
          [_vm._v("ETA")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable"
          },
          [_vm._v("Type")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable"
          },
          [_vm._v("Amount")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BaseEmployeeTransferTable"
          },
          [_vm._v("Status")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }