var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DashboardEmployeeTransfers" },
    [
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        _vm.wires
          ? _c(
              "div",
              { staticClass: "column mb-31" },
              [
                _c("div", { staticClass: "row mb-17" }, [
                  _c("label", { staticClass: "fs-18 fw-med" }, [
                    _vm._v("Upcoming wires")
                  ])
                ]),
                _c("base-wires-table", {
                  attrs: { wires: _vm.wires },
                  on: { "client-wire-sent": _vm.refetchWiresAndTransfers }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "Transition__fade" } }, [
        _vm.achTransfers
          ? _c(
              "div",
              { staticClass: "column mb-31" },
              [
                _c("div", { staticClass: "row mb-17" }, [
                  _c("label", { staticClass: "fs-18 fw-med" }, [
                    _vm._v("Upcoming ACH")
                  ])
                ]),
                _c("base-ach-table", {
                  attrs: { transfers: _vm.achTransfers },
                  on: { "ach-sent": _vm.refetchWiresAndTransfers }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "column" },
        [
          _vm._m(0),
          _c("div", { staticClass: "row row--justify-between mb-31" }, [
            _c(
              "div",
              { staticClass: "row row--align-top" },
              [
                _c("base-date-picker", {
                  attrs: {
                    instruction: _vm.instructions.TRANSFER_DATE_INSTRUCTION,
                    placeholder: "Date: Any",
                    valid: _vm.transferDate.valid
                  },
                  model: {
                    value: _vm.transferDate.value,
                    callback: function($$v) {
                      _vm.$set(_vm.transferDate, "value", $$v)
                    },
                    expression: "transferDate.value"
                  }
                }),
                _c("v-select", {
                  staticClass: "DashboardEmployeeTransfers__select ml-10",
                  attrs: {
                    "aria-label": "Select input to choose transfer type",
                    clearable: false,
                    options: _vm.transferTypeOptions,
                    placeholder: "Transfer type:"
                  },
                  model: {
                    value: _vm.transferType,
                    callback: function($$v) {
                      _vm.transferType = $$v
                    },
                    expression: "transferType"
                  }
                }),
                _c(
                  "base-button",
                  {
                    staticClass: "bg-white fc-blue ml-10",
                    on: {
                      click: function($event) {
                        return _vm.search(null)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-search-left" }),
                    _vm._v("\n          Search\n        ")
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "transition",
            { attrs: { name: "Transition__fade" } },
            [
              _vm.transfers && _vm.transfers.length
                ? _c("base-employee-transfer-table", {
                    attrs: { transfers: _vm.transfers },
                    on: { "reload-nacha": _vm.search }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("base-pagination", {
            staticClass: "mt-22",
            attrs: {
              "item-count": _vm.transfersCount,
              page: _vm.transfersPage,
              "request-limit": _vm.transfersPerPage
            },
            on: { paginate: _vm.search }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mb-17" }, [
      _c("label", { staticClass: "fs-18 fw-med" }, [_vm._v("Transfers")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }