<template>
  <div class="DashboardEmployeeTransfers">
    <!-- CLIENT WIRE(S) -->
    <transition name="Transition__fade">
      <div
        v-if="wires"
        class="column mb-31"
      >
        <div class="row mb-17">
          <label class="fs-18 fw-med">Upcoming wires</label>
        </div>

        <base-wires-table
          @client-wire-sent="refetchWiresAndTransfers"
          :wires="wires"
        />
      </div>
    </transition>

    <!-- CLIENT ACH(S) -->
    <transition name="Transition__fade">
      <div
        v-if="achTransfers"
        class="column mb-31"
      >
        <div class="row mb-17">
          <label class="fs-18 fw-med">Upcoming ACH</label>
        </div>

        <base-ach-table
          @ach-sent="refetchWiresAndTransfers"
          :transfers="achTransfers"
        />
      </div>
    </transition>

    <!-- TRANSFERS -->
    <div class="column">
      <div class="row mb-17">
        <label class="fs-18 fw-med">Transfers</label>
      </div>

      <!-- INPUT ELEMENTS -->
      <div class="row row--justify-between mb-31">
        <div class="row row--align-top">
          <base-date-picker
            v-model="transferDate.value"
            :instruction="instructions.TRANSFER_DATE_INSTRUCTION"
            :placeholder="'Date: Any'"
            :valid="transferDate.valid"
          />
          <v-select
            v-model="transferType"
            aria-label="Select input to choose transfer type"
            class="DashboardEmployeeTransfers__select ml-10"
            :clearable="false"
            :options="transferTypeOptions"
            :placeholder="'Transfer type:'"
          />
          <!-- search is looking for a page param, but we want to search from the first page -->
          <base-button
            @click="search(null)"
            class="bg-white fc-blue ml-10"
          >
            <i class="fa fa-search-left" />
            Search
          </base-button>
        </div>
      </div>

      <!-- TRANSFERS -->
      <transition name="Transition__fade">
        <base-employee-transfer-table
          v-if="transfers && transfers.length"
          @reload-nacha="search"
          :transfers="transfers"
        />
      </transition>

      <base-pagination
        @paginate="search"
        class="mt-22"
        :item-count="transfersCount"
        :page="transfersPage"
        :request-limit="transfersPerPage"
      />
    </div>
  </div>
</template>

<script>
// Packages
import moment from 'moment-timezone'
// Helpers
import {
  BatchPayment,
  Client,
} from '../../utils/api'
// Components
import BaseAchTable from '../../components/base-ach-table.vue'
import BaseButton from '../../components/base-button.vue'
import BaseDatePicker from '../../components/base-date-picker.vue'
import BaseEmployeeTransferTable from '../../components/base-employee-transfer-table.vue'
import BasePagination from '../../components/base-pagination.vue'
import BaseWiresTable from '../../components/base-wires-table.vue'
// Mixins
import {
  ValidationMixin
} from '../../utils/validation-mixin'
import { secondAchHours } from '../../utils/helpers'

export default {
  name: 'DashboardEmployeeTransfers',

  components: {
    BaseAchTable,
    BaseButton,
    BaseDatePicker,
    BaseEmployeeTransferTable,
    BasePagination,
    BaseWiresTable,
  },

  mixins: [ValidationMixin],

  async created () {
    await this.getWires()
    await this.search()
    await this.getACH()
  },

  data () {
    return {
      achTransfers: null,
      transferDate: {
        gt_value: null,
        lt_value: null,
        valid: true,
        value: null,
      },
      transferType: 'All',
      transferTypeOptions: [
        'All',
        'ACH',
        'Wires'
      ],
      transfers: null,
      transfersPerPage: 10,
      transfersCount: null,
      transfersPage: 1,
      wires: null,
    }
  },

  computed: {
    nextWireTime () {
      // Wires transfer every hour starting at 1000 and ending at 1700
      // The transfer time should be the next upcoming hour within ^
      const nextHour = moment().tz('America/New_York').add(1, 'hours')

      if (nextHour.get('hours') > 17) return '10am'
      if (nextHour.get('hours') < 10) return '10am'
      return nextHour.format('ha z')
    }
  },

  methods: {
    getTransferType () {
      if (this.transferType === 'All') return null
      if (this.transferType === 'Wires') return 'wire'
      return 'ach'
    },

    async getACH () {
      this.progressStart()
      try {
        const data = (await Client.getACH()).data
        this.achTransfers = data.transfers
        this.progressFinish()
      } catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Transfers "Get ACH"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting client ACH' })
      }
    },

    async getWires (sentWireId = null) {
      this.progressStart()
      try {
        // if a wire was sent remove it from display
        this.wires = (await Client.getWires()).data
          .filter(wire => wire.id !== sentWireId)
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Transfers "Get Wires"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
            sentWireId,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting client wires' })
      }
    },

    // Used to update the transfers table when a wire is expedited
    async refetchWiresAndTransfers (wireId) {
      // Reset pagination
      this.transfersCount = this.$options.data().transfersCount
      this.transfersPage = this.$options.data().transfersPage
      await this.getWires(wireId)
      await this.search()
    },

    async search (page = null) {
      if (!this.validation()) return
      if (page) this.transfersPage = page

      this.progressStart()
      if (this.transferDate.value) {
        // A day is considered from second transfer (8pm) previous day
        const prevDaySecondTransfer = moment(this.transferDate.value)
          .startOf('day')
          .subtract((24 - secondAchHours), 'hours')
          .toDate()
        const currentDaySecondTransfer = moment(this.transferDate.value)
          .startOf('day')
          .add(secondAchHours, 'hours')
          .toDate()
        this.$set(this.transferDate, 'gt_value', prevDaySecondTransfer)
        this.$set(this.transferDate, 'lt_value', currentDaySecondTransfer)
      }

      try {
        const data = (await BatchPayment.queryList({
          gt___amount: 0,
          gt___updated_at: this.transferDate.gt_value,
          include: 'full_payments',
          limit: this.transfersPerPage,
          lt___updated_at: this.transferDate.lt_value,
          offset: page && !isNaN(page) ? (page - 1) * this.transfersPerPage : 0,
          sort_by: 'updated_at',
          sort_direction: 'desc',
          transfer_type: this.getTransferType()
        })).data
        this.transfers = data.rows
          .map(transfer => Object.assign(transfer, { expanded: false }))
        this.transfersCount = data.count
        this.progressFinish()
      } catch (error) {
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the transfers' })
      }
    },

    validation () {
      let valid = true

      if (this.transferDate.value && !this.isValidTransferDate(this.transferDate.value)) {
        this.$set(this.transferDate, 'valid', false)
        valid = false
      } else {
        this.$set(this.transferDate, 'valid', true)
      }

      return valid
    }
  }
}
</script>

<style lang="sass">
.DashboardEmployeeTransfers

  &__select
    width: rem(215px)

    .vs__selected
      &::before
        content: 'Transfer type:'
        padding-right: rem(4px)

</style>
