<template>
  <div
    :class="['Table', { 'Table--mobile': mobile.isMobile }]"
    data-cy="base-wires-table"
  >
    <!-- If you want a heading and subheading in the top of the table -->
    <!-- Content passed here should have class: Table__slot-content -->
    <slot />

    <div class="Table__head-wrapper row row--justify-between">
      <label class="Table__header-lbl Table__header-lbl--BaseWiresTable">Amount</label>
      <label class="Table__header-lbl Table__header-lbl--BaseWiresTable">Client</label>
      <!-- ACTIONS (hidden) -->
      <label class="Table__header-lbl Table__header-lbl--BaseWiresTable" />
    </div>

    <div
      v-for="(wire, index) in wires"
      :key="index"
      class="Table__item--BaseWiresTable"
    >
      <div class="Table__item-wrapper Table__item-wrapper--BaseWiresTable row row--align-center">
        <!-- AMOUNT -->
        <div class="column">
          <label
            class="fs-15 fc-light"
            :data-cy="`base-wires-table-${index}-amount-label`"
          >
            {{
              Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                .format((wire.wire_total / 100))
            }}
          </label>
        </div>

        <!-- CLIENT SHORT NAME -->
        <div class="column">
          <label
            @click.stop="goToClientProfile(wire.id)"
            class="fs-15 fc-light clickable"
            :data-cy="`base-wires-table-${index}-shortened-name`"
            tabindex="0"
          >
            {{ wire.shortened_name }}
          </label>
        </div>

        <!-- BUTTON TO RUN WIRE -->
        <div class="column column--align-end">
          <base-button
            @click="showModal(wire)"
            class="BaseWiresTable__send-wire-btn bg-blue fc-white fs-14"
            :data-cy="`base-wires-table-${index}-expedite-button`"
          >
            Expedite Wire
          </base-button>
        </div>
      </div>
    </div>

    <transition name="Transition__fade">
      <base-modal v-if="modalVisible">
        <template v-slot:label>
          Do you want to expedite a wire for
          {{
            Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format((wireToExpedite.wire_total / 100))
          }}
          to
          {{ wireToExpedite.shortened_name }}?
        </template>

        <template v-slot:actions>
          <base-button
            @click="expediteClientWire"
            class="bg-blue fc-white mr-8"
            data-cy="base-wires-table-send-button"
            :disabled="sendButtonDisabled"
          >
            Send
          </base-button>
          <base-button
            @click="hideModal"
            class="bg-light fc-white"
          >
            Cancel
          </base-button>
        </template>
      </base-modal>
    </transition>
  </div>
</template>

<script>
// Helpers
import { Cron } from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseModal from './base-modal.vue'

export default {
  name: 'BaseWiresTable',

  components: {
    BaseButton,
    BaseModal,
  },

  props: {
    wires: {
      type: Array,
      required: true,
      default: null
    }
  },

  data () {
    return {
      modalVisible: false,
      sendButtonDisabled: false,
      wireToExpedite: null,
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },

  methods: {
    async expediteClientWire () {
      this.sendButtonDisabled = true
      this.progressStart()

      try {
        await Cron.runClientWire({ id: this.wireToExpedite.id })
        this.$emit('client-wire-sent', this.wireToExpedite.id)
        this.modalVisible = false
        this.wireToExpedite = null
        // The button should be displayed until the very last step
        this.sendButtonDisabled = false
        this.progressFinish()
      }
      catch (error) {
        // I don't want to enable the submit button on an error.
        // I really want to make sure it's clear something went wrong
        // and a user should just try again. If the user clicks "cancel,"
        // the button will re-enabled
        this.captureSentryEvent(
          'Wires Table "Expedite Wire" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: `
            There was an issue sending the client\'s wire.
            Please reach out to your administrators to get more information on the error.
          `
        })
      }
    },

    goToClientProfile (id) {
      this.$router.push({
        name: 'client',
        params: {
          id,
        }
      })
    },

    hideModal () {
      this.sendButtonDisabled = false
      this.modalVisible = false
      this.wireToExpedite = null
    },

    showModal (wire) {
      this.wireToExpedite = wire
      this.modalVisible = true
    },
  },
}
</script>

<style lang="sass">
@import '../styles/tables.sass'

.BaseWiresTable

  &__send-wire-btn
    padding: rem(10px) rem(13px)
</style>